<template>
  <div class="member-index">
    <el-form
      ref="editForm"
      label-width="100px"
      class="member-form"
      :rules="rules"
      :model="editForm"
    >
      <el-form-item label="所在地区" prop="area">
        <el-cascader
          clearable
          :props="{ value: 'id', label: 'name', children: 'son' }"
          :value="area"
          @change="onChangeArea"
          placeholder="请选择"
          :options="options"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="小区名称" prop="name">
        <el-input
          class="w-300"
          v-model="editForm.name"
          placeholder="例:某某小区1#01户型"
        ></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="TypeID">
        <el-select v-model="editForm.TypeID" @change="onChangeType">
          <el-option
            v-for="item in types"
            :key="item.id"
            :value="item.id"
            :label="item.TypeName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        v-if="[1, 2, 5].includes(editForm.TypeID)"
        label="平方数"
        prop="square"
      >
        <el-input-number
          v-model="editForm.square"
          @change="getPrice"
          controls-position="right"
        ></el-input-number>
        ㎡（套内）
      </el-form-item>
      <el-form-item label="金额" prop="money">
        <el-input-number
          :disabled="[1, 2, 5].includes(editForm.TypeID)"
          v-model="editForm.money"
          controls-position="right"
        ></el-input-number>
      </el-form-item>
      <!-- <el-form-item  v-if="editForm.TypeID ===5" label="倍数" prop="magnification">
        <el-input-number :min="1" :max="8" v-model="editForm.magnification" controls-position="right"></el-input-number>
      </el-form-item> -->
      <el-form-item label="描述" prop="description">
        <el-input
          class="w-300"
          v-model="editForm.description"
          type="textarea"
          rows="5"
          placeholder="请简略描述所上传资源内容"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="所属省份">
        <el-select></el-select>
      </el-form-item>
      <el-form-item label="所在城市">
        <el-select></el-select>
      </el-form-item> -->

      <el-form-item label="上传资源" prop="url">
        <el-upload
          class="upload-demo"
          drag
          :on-success="handleFileSuccess"
          :before-upload="beforeFileUpload"
          accept=".rar,.zip"
          :action="`${apiPrefix}/UploadUrl`"
          :multiple="false"
          :file-list="fileList"
          :on-remove="onRemove"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            注：请上传CAD文件不可高于07版本，所有文件以压缩包形式上传
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="封面图" prop="pic">
        <el-upload
          class="avatar-uploader"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :action="`${apiPrefix}/UploadPic`"
          :show-file-list="false"
          accept=".jpg,.png"
        >
          <img
            v-if="editForm.pic"
            :src="imagePrefix + editForm.pic"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <div class="el-upload__tip" slot="tip">
            注：所上传封面不可带有明显的公司或个人信息
          </div>
        </el-upload>
        <div class="example">
          <span>例如：</span>
          <el-image
            :preview-src-list="[imageMaps[editForm.TypeID]]"
            :src="imageMaps[editForm.TypeID]"
          />
        </div>
      </el-form-item>
      <el-form-item label="公开联系方式">
        <el-switch
          v-model="editForm.ISprivacy"
          active-value="true"
          inactive-value="false"
        >
        </el-switch>
      </el-form-item>
    </el-form>
    <!-- <el-form-item label=""> -->
    <div class="upload-footer">
      <div class="upload-tips">
        <p><b>上传说明：</b></p>
        <p>
          1.每个相同的户型框架图最多上传三个，超过三个相同的户型框架图无法审核通过。
        </p>
        <p>
          2.户型方案，施工图，效果图，开工图，上传不限数量，但已上传存在相同或类似的无法审核通过。
        </p>
        <p>
          3.上传用户分成比例，户型框架图（百分之二十五）户型方案（百分之三十），户型开工图（百分之三十五）
          户型效果和户型施工图（百分之是四十五）
        </p>
      </div>
      <!-- </el-form-item> -->
      <!-- <el-form-item> -->
      <template v-if="editForm.id">
        <div style="text-align: center">
          <el-button type="primary" @click="onConfirm">提交修改</el-button>
          <el-button @click="$emit('close')">关闭</el-button>
        </div>
      </template>
      <template v-else>
        <el-button type="primary" @click="onConfirm">立即上传</el-button>
        <el-button @click="reset">重置</el-button>
      </template>

      <!-- </el-form-item> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getCity, getModelType, addUpload, editUpload } from "@/api";
import { getMoney } from "@/api";
import example1 from "@/assets/example_1.png";
import example2 from "@/assets/example_2.png";
import example3 from "@/assets/example_3.png";
import example4 from "@/assets/example_4.png";
import example5 from "@/assets/example_5.png";

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      editForm: {
        id: 0,
        name: "",
        description: "",
        TypeID: 1,
        money: 0,
        square: 1,
        ISprivacy: "true",
        // magnification: 1,
        pic: "",
        url: "",
        FileSize: "",
        area: "",
        site: "",
      },
      imageMaps: {
        1: example1,
        2: example2,
        3: example3,
        4: example4,
        5: example5,
      },
      rules: {
        name: [{ required: true, message: "请输入小区名称", trigger: "blur" }],
        // description: '',
        TypeID: [{ required: true, message: "请选择类型", trigger: "change" }],
        money: [{ required: true, message: "请输入金额", trigger: "blur" }],
        square: [{ required: true, message: "请输入平方数", trigger: "blur" }],
        magnification: [
          { required: true, message: "请输入倍数", trigger: "blur" },
        ],
        pic: [{ required: true, message: "请上传封面图", trigger: "blur" }],
        url: [{ required: true, message: "请上传文件", trigger: "blur" }],
        FileSize: "",
        area: [{ required: true, message: "请选择所在区域", trigger: "blur" }],
        site: "",
      },
      options: [],
      types: [],
      fileList: [],
    };
  },
  created() {
    this.getCity();

    this.getTypes();
    if (this.data.id) {
      // console.log("this.data, this.editForm >>", this.data, this.editForm);
      const name = this.data.url.split("/");
      this.area = [];
      this.editForm.id = this.data.id;
      this.editForm.name = this.data.name;
      this.editForm.description = this.data.description;
      this.editForm.TypeID = this.data.TypeID;
      this.editForm.money = this.data.money;
      this.editForm.square = this.data.square;
      this.editForm.pic = this.data.pic;
      this.editForm.url = this.data.fid;
      this.editForm.FileSize = this.data.FileSize;
      this.editForm.area = parseInt(this.data.area);
      this.editForm.site = parseInt(this.data.site);
      this.editForm.ISprivacy = this.data.ISprivacy;
      this.fileList = [
        {
          name: name[name.length - 1],
          size: this.data.FileSize,
          status: "success",
        },
      ];
    } else {
      this.getPrice();
    }
  },
  mounted() {
    // this.getAd();
    // this.getRank();
  },
  computed: {
    ...mapGetters(["userinfo"]),
    area: {
      get() {
        return [this.editForm.area, this.editForm.site];
      },
      set(val) {
        this.editForm.area = val[0];
        this.editForm.site = val[1];
      },
    },
    apiPrefix() {
      return window.globalConfig.VUE_APP_API_URL;
    },
    imagePrefix() {
      return window.globalConfig.VUE_APP_IMAGE_URL;
    },
  },
  methods: {
    getPrice() {
      if ([1, 2, 5].includes(this.editForm.TypeID)) {
        getMoney({
          type: this.editForm.TypeID,
          square: this.editForm.square,
        }).then((res) => {
          this.editForm.money = res;
        });
      } else {
        this.editForm.money = 0;
      }
    },
    onChangeType() {
      this.editForm.square = 1;
      this.getPrice();
    },
    handleFileSuccess(res, file, fileList) {
      console.log("res >>", res, fileList);
      this.editForm.url = res.data.id;
      this.editForm.FileSize = res.data.FileSize;

      this.fileList = fileList.slice(-1);
    },
    onRemove() {
      this.editForm.url = "";
      this.editForm.FileSize = "";
    },
    beforeFileUpload(file) {
      console.log("file >>", file);

      const FileExt = file.name.replace(/.+\./, "");
      // const isJPG =
      //   file.type === "application/x-rar-compressed" ||
      //   file.type === "application/x-zip-compressed";
      // const isLt2M = file.size / 1024 / 1024 < 2;

      if (!["rar", "zip"].includes(FileExt.toLowerCase())) {
        this.$message.error("资源文件只能是 rar 或 zip 格式!");
        return false;
      }
      return true;
      // if (!isLt2M) {
      //   this.$message.error("封面图片大小不能超过 2MB!");
      // }
      // return isJPG && isLt2M;
    },
    handleAvatarSuccess(res) {
      console.log("res >>", res);
      this.editForm.pic = res.data.url;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("封面图片只能是 JPG 或 png 格式!");
      }
      if (!isLt2M) {
        this.$message.error("封面图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    onChangeArea(val) {
      this.area = val;
    },
    reset() {
      this.$refs.editForm.resetFields();
      this.fileList = [];
      if (this.editForm.id) {
        this.$emit("confirm", "close");
        return;
      }
      this.editForm = {
        name: "",
        description: "",
        TypeID: 1,
        money: 0,
        square: 1,
        magnification: 1,
        pic: "",
        url: "",
        FileSize: "",
        area: "",
        site: "",
      };
      this.getPrice();
    },
    async getCity() {
      const res = await getCity({});
      console.log("res >>", res);
      this.options = res;
    },
    async getTypes() {
      const res = await getModelType({});
      this.types = res;
      console.log("res >>", res);
    },
    onConfirm() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          const func = this.editForm.id ? editUpload : addUpload;
          if ([1, 2, 5].includes(this.editForm.TypeID)) {
            getMoney({
              type: this.editForm.TypeID,
              square: this.editForm.square,
            }).then((res) => {
              this.editForm.money = res;
              func({
                ...this.editForm,
                creator: this.userinfo.UserName,
              }).then(() => {
                this.$message.success("上传成功");
                this.reset();
              });
            });
          } else {
            func({
              ...this.editForm,
              creator: this.userinfo.UserName,
            }).then(() => {
              this.$message.success("上传成功");
              this.reset();
            });
          }
        } else {
          this.$message.warning("请认真填写内容！");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.member-index {
  padding: 20px;
  background: #fff;
  min-height: 561px;
  // display: flex;
  // justify-content: center;
  .member-form {
    width: 700px;
  }
  ::v-deep .avatar-uploader {
    display: inline-block;
  }
  ::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
.example {
  display: inline-flex;
  vertical-align: top;
  color: #777;
  .el-image {
    width: 250px;
  }
}
.w-300 {
  width: 400px;
}
.c-p {
  cursor: pointer;
}
.upload-tips {
  margin-top: 30px;
  margin-bottom: 30px;
  p {
    line-height: 1.15;
    margin-bottom: 8px;
    color: #555;
  }
}
.upload-footer {
  padding: 0 80px;
}
</style>
